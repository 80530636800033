import { Fragment, useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';

import { DataModels } from '@atlas-engine/atlas_engine_sdk';

import { AnyTaskType, EngineContext } from '../../../lib/index';

export type CorrelationFlashMessageProps = {
  correlation: DataModels.Correlation.Correlation;
  tasks: Array<AnyTaskType>;
};

export function CorrelationFlashMessage(props: CorrelationFlashMessageProps): JSX.Element | null {
  const engineService = useContext(EngineContext);

  const correlationIsFinished =
    props.correlation.processInstances?.every(
      (processInstance) => processInstance.state === DataModels.ProcessInstances.ProcessInstanceState.finished
    ) ?? true;

  if (engineService && correlationIsFinished) {
    return <ProcessFinishedMessage />;
  }

  const errorMessages =
    props.correlation.processInstances?.map((instance) => {
      if (instance.state !== DataModels.ProcessInstances.ProcessInstanceState.error) {
        return null;
      }

      const terminatedBy = (instance.error as any)?.additionalInformation?.terminatedBy;
      if (terminatedBy !== undefined) {
        return <ProcessTerminatedMessage terminatedBy={terminatedBy} key={instance.processInstanceId} />;
      }

      return (
        <GenericProcessErrorMessage error={instance.error} processModel={instance} key={instance.processInstanceId} />
      );
    }) ?? [];

  if (props.tasks.length === 0) {
    errorMessages.push(<NoTasksInCorrelationMessage correlation={props.correlation} key="no-task-message" />);
  }

  return <Fragment>{errorMessages}</Fragment>;
}

function ProcessFinishedMessage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Alert variant="success" className="mb-2">
      {t('CorrelationTracker.ProcessSuccessfullyCompleted')}
      <LinkContainer to="/">
        <Alert.Link>{t('CorrelationTracker.SwitchToOverview')}</Alert.Link>
      </LinkContainer>
    </Alert>
  );
}

function ProcessTerminatedMessage(props: { terminatedBy: any }): JSX.Element {
  const { t } = useTranslation();
  return (
    <Alert variant="warning" className="mb-2">
      {t('CorrelationTracker.ProcessAborted', {
        userId: props.terminatedBy.userId || t('CorrelationTracker.UserIdUnknown'),
      })}
      <LinkContainer to="/">
        <Alert.Link>{t('CorrelationTracker.SwitchToOverview')}</Alert.Link>
      </LinkContainer>
    </Alert>
  );
}

function GenericProcessErrorMessage(props: {
  error: any;
  processModel: DataModels.ProcessInstances.ProcessInstance;
}): JSX.Element {
  const { t } = useTranslation();
  return (
    <Alert variant="danger" className="mb-2">
      {t('CorrelationTracker.ErrorProcessExecution', {
        processModelId: props.processModel.processModelName ?? props.processModel.processModelId,
      })}{' '}
      <code>{JSON.stringify(props.error)}</code>.
    </Alert>
  );
}

function NoTasksInCorrelationMessage(props: { correlation: DataModels.Correlation.Correlation }): JSX.Element {
  const { t } = useTranslation();

  // TODO: May be moved to a dedicated "progressDescription" property in future engine updates.
  const correlationProgressDescription = props.correlation?.metadata?.progressDescription?.trim();
  const message =
    correlationProgressDescription && correlationProgressDescription.length > 0
      ? correlationProgressDescription
      : t('CorrelationTracker.CurrentlyNoTasksAvailable');

  return (
    <Alert variant="info" className="mb-2">
      {message}
      <p className="mb-0">
        <small>{t('CorrelationTracker.ProcessWorksInBackground')}</small>
      </p>
    </Alert>
  );
}
